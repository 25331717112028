import axios from 'axios'
import { apiV1 } from '@/config'

export const loadCsvImportProfiles = ({ commit }) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullCsvImportProfiles.php', {}, { params: { action: 'getCsvImportProfiles' } })
    .then(({ data }) => resolve(data.list))
    .catch(error => reject(error))
})

export const transferOrders = ({ commit }, options) => new Promise((resolve, reject) => {
  axios.post('/ajax/PullOrders.php', options, { params: { action: 'transferOrders' } })
    .then(({ data }) => resolve(data))
    .catch(error => reject(error))
})

export const getDetails = (ctx, { orderId, force }) => new Promise((resolve, reject) => {
  const action = force ? 'getDetailsForce' : 'getDetails'
  axios.post('/ajax/PullOrdersDetails.php', { orderId }, { params: { action }, toast: 'Loading, Please wait...' })
    .then(({ data: { order } }) => resolve(order))
    .catch(e => reject(e))
})

export const getNotesResultPrepared = () => new Promise((resolve, reject) => {
  axios.get('/ajax/PullOrders.php', { params: { action: 'getNotesResultPrepared' } })
    .then(({ data: { list } }) => resolve(list))
    .catch(error => reject(error))
})

export const getOrderStatuses = () => axios
  .get(`${apiV1}/common/get-order-statuses`)
  .then(({ data: { statuses } }) => statuses)

export const resetPioneerRxSync = (ctx, post) => axios
  .post('/ajax/PullOrders.php', post, { params: { action: 'resetPioneerRxSync' } })
  .catch(() => {})

export const createOrdersBatch = (ctx, post) => axios
  .post(`${apiV1}/order/batch`, post)
  .then(({ data: { id } }) => id)
  .catch(() => null)

export const transferToRx2go = (ctx, post) => axios
  .post('/ajax/PullOrders.php', post, { params: { action: 'transferToRx2go' } })
  .then(({ data: { id } }) => id)
  .catch(() => null)
